<template>
<div id="body">
    <div class="header">
        <img class="headerimg" src="@/assets/images/directions.jpg" alt="directions">
        <div class="headerfont">DIRECTIONS</div>
    </div>
    <form action=http://maps.google.com/maps method=”get” target=”_blank”>
        <label>Get Directions From:</label>
        <input type="text" name="saddr" id="addressinput">
        <input type="hidden" name="daddr" id="saddr" value="219 Dryville Rd, Fleetwood, PA 19522">
        <input type="submit" value="Get Directions" id="getdirectionsbutton">
       <!-- eslint-disable-next-line -->
    </form>
    <div id="mapinfo">
        <div id="map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d194271.72305774235!2d-75.92738189968323!3d40.46429799725777!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c5d41470a9aa13%3A0x11e7b971c54c507f!2sShady+Mountain+Market!5e0!3m2!1sen!2sus!4v1535056531856" width="600" height="450" frameborder="0" style="border:0" allowfullscreen></iframe>
        </div>
        
        <Hours class="hours"/>
    </div>
</div>
</template>
<style scoped lang="scss">
#mapinfo {
    display: flex;
    flex-direction: column;
	margin: 10px;
    .hours {
        display: none;
    }
    @media (min-width: 600px) {
            margin-left: 30px;
            display: flex;
            flex-direction: row;
            .hours {
                display: flex;
                min-width: 120px;
                background-color: #411211;
                padding: 20px;
                color: #fff;
                font-family: "futura-pt";
                margin-left: 30px;
                margin-bottom: 10px;
                align-self: flex-start;
		}
    }
}
</style>
<script>
import Hours from "@/components/Hours.vue";

export default {
	components: {
		Hours
	}
}
</script>